const Navbar = () => {
	return (
		<nav>
			<ul>
				<li>
					<span className="nav-logo">Minipay-Dapp</span>
				</li>
			</ul>
		</nav>
	);
};

export default Navbar;
